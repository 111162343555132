exports.components = {
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-game-tsx": () => import("./../../../src/templates/game.tsx" /* webpackChunkName: "component---src-templates-game-tsx" */),
  "component---src-templates-games-tsx": () => import("./../../../src/templates/games.tsx" /* webpackChunkName: "component---src-templates-games-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-not-found-tsx": () => import("./../../../src/templates/not-found.tsx" /* webpackChunkName: "component---src-templates-not-found-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-portfolio-tsx": () => import("./../../../src/templates/portfolio.tsx" /* webpackChunkName: "component---src-templates-portfolio-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

